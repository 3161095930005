import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "../src/Pages/Home";
import MintETH from "../src/Pages/mintETH";
import MintETH2 from "../src/Pages/ref";
import MintPoly from "../src/Pages/mintPoly";
import MintAvax from "../src/Pages/mintAvax";
import MintBNB from "../src/Pages/mintBNB";
import MintBase from "../src/Pages/mintBase";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<MintETH />} />
					<Route path='/MATIC' element={<MintPoly />} />
					<Route path='/AVAX2' element={<MintAvax />} />
					<Route path='/AVAX' element={<MintBNB />} />
					<Route path='/BASE' element={<MintBase />} />
					<Route path="/:_userWalletAddress" element={<MintETH2 />} />

				</Routes>
			</BrowserRouter>

		</div>

	)
}

export default App;
